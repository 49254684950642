export default Object.freeze({
	aside_sizes: { large: 700, medium: 550, small: 450 },
	case_item_types: {
		ai_generated: { id: 5 },
		ai_instructions: { id: 12 },
		ai_message: { id: 13 },
		ask_statement: { id: 11 },
		ask_statement_all_test_answers: { id: 6 },
		ask_statement_not_necessary: { id: 8 },
		ask_statement_outside_test_answers: { id: 7 },
		comment: { id: 1 },
		draft: { id: 15 },
		email: { id: 2 },
		malpractice: { id: 9 },
		message: { id: 3 },
		phone: { id: 4 },
		references: { id: 10 },
		statement: { id: 14 },
	},
	languages: [
		{
			id: 5,
			name: 'English',
			symbol: 'en',
		},
		{
			id: 1,
			name: 'Swedish',
			symbol: 'sv',
		},
	],
	template_types: {
		case_item: { id: 7 },
		chat: { id: 8 },
	},
});
